import React, { useEffect } from "react";
import "../../Css/CarDetail/DealersParticipantes.css";
import Footer from "../UI/Footer";
import Navbar from "../UI/NavbarTwo";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";

const DealersParticipantes = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);
  return (
    <>
      <Helmet>
        <title>Kia EV6 | Dealers Participantes | Reserva en línea</title>
        <link rel="canonical" href="https://kiaevpr.com/#/DealersParticipantes" />
        <meta
          name="description"
          content="Conoce los dealers participantes donde puedes comprar el nuevo y totalmente eléctrico Kia EV6. ¡Hay un dealer participante cerca de ti!"
        />
      </Helmet>
      <Navbar />
      <div className="top-div"></div>
      <section className="Dealers-participantes-sec">
        <div className="Dealers-participantes-div">
          <div>
            <div className="top-sec-div"></div>
            <div className="span-div-divvv">
              <span className="Dealers-participantes-span">
                {/* Concesionarios autorizados */}
                Concesionarios participantes
              </span>
              <div className="extra-div"></div>
            </div>
            <div className="form-span-div mt-5">
              <div>
                <span className="Area-span">Área Metro</span>
              </div>
              <div className="mt-3">
                <span className="AutoGrupo-all-span ">Auto Grupo</span>
              </div>
              <div>
                <p className="AutoGrupo-all-span ">
                  Marginal Kennedy km. 3.2 Sector Bechara San Juan, 00929
                </p>
              </div>
              <div className="mt-3 AutoGrupo-all-span ">
                <span>Caribbean Auto Distributors</span>
              </div>
              <div>
                <p className="AutoGrupo-all-span">
                  Avenida 65 De Infantería, KM 10.2 Esquina Muñoz Rivera,
                  Carolina, 00987
                </p>
              </div>

              <div className="mt-3">
                <span className="AutoGrupo-all-span">
                 Tocars Kia
                </span>
              </div>
              <div>
                <p className="AutoGrupo-all-span">
                Carr. #2, km 16.4 Bo. Covadonga, Tao Baja, 00949
                </p>
              </div>
              <div className="mt-3">
                <span className="AutoGrupo-all-span">
                  Toñito Auto Río Piedras
                </span>
              </div>
              <div>
                <p className="AutoGrupo-all-span">
                  Ave. 65 de Infantería KM. 2.2, San Juan 00929
                </p>
              </div>
              <div className="mt-3">
                <span className="AutoGrupo-all-span">Yokomuro</span>
              </div>

              <div>
                <p className="AutoGrupo-all-span">
                  PR-167 Esquina Esther, KM. 18.7, Bayamón, 00956
                </p>
              </div>
              <div className="mt-5">
                <span className="Centro-span">Centro</span>
              </div>
              <div className="mt-3">
                <span className=" AutoGrupo-all-span">Cidra Auto Mall</span>
              </div>
              <div>
                <p className="AutoGrupo-all-span">
                  86 C. Antonio R. Barcelo, Cidra, 00739
                </p>
              </div>
              <div className="mt-3">
                <span className=" AutoGrupo-all-span">Medina Auto</span>
              </div>
              <div>
                <p className="AutoGrupo-all-span">
                  Carr. Núm. 1 KM.29.7, Caguas, 00725
                </p>
              </div>
              <div className="mt-3">
                <span className="AutoGrupo-all-span">Pepe Abad</span>
              </div>
              <div>
                <p className="AutoGrupo-all-span">
                  Carr. #1 KM 50.8 Bo. Beatriz, Cayey, 00739
                </p>
              </div>
              <div className="mt-5">
                <span className="Centro-span">Norte</span>
              </div>
              <div className="mt-3">
                <span className="AutoGrupo-all-span">Toñito Auto</span>
              </div>
              <div>
                <p className="AutoGrupo-all-span">688 PR-2, Vega Baja,00693</p>
              </div>
              <div className="mt-5">
                <span className="Centro-span">Sur</span>
              </div>
              <div className="mt-3">
                <p className="AutoGrupo-all-span">Señorial Auto</p>
              </div>
              <div>
                <p className="AutoGrupo-all-span">
                  2395 Ponce By Pass, Ponce, 00717
                </p>
              </div>
              <div className="mt-5">
                <span className="Centro-span">Oeste</span>
              </div>
              <div className="mt-3">
                <span className="AutoGrupo-all-span">Auto Stop</span>
              </div>
              <div>
                <p className="AutoGrupo-all-span">
                  Carr. #111, km. 15.5, San Sebastián, 00685
                </p>
              </div>
              <div className="margin-div"></div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default DealersParticipantes;
