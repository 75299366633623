/* eslint-disable no-undef */
import React, {
  useContext,
  useEffect,
  useState,
  // , { useEffect }
} from "react";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  // , useLocation
} from "react-router-dom";
import LandingLayout from "./Components/Sections/LandingPage/LandingLayout";
import Error from "./Pages/Others/Error";
import Login from "./Pages/Auth/Login";
import ChangePassword from "./Pages/Auth/ChangePassword";
import Privacy from "./Pages/Policy/Privacy";
import PurchaseReturn from "./Pages/Policy/PurchaseReturn";
import TermsConditions from "./Pages/Policy/TermsConditions";
import ForgotPassword from "./Pages/Auth/ForgotPassword";
import Intro from "./Pages/Alerts/Intro";
import RegisterYes from "./Pages/Auth/RegisterYes";
import RegisterAddress from "./Pages/Auth/RegisterAddress";
import Cart from "./Pages/User/Cart";
import ScrollToTop from "./Components/ScrollTop";
import FAQ from "./Pages/FAQs/FAQ";
import FAQTwo from "./Pages/FAQs/FAQTwo";
import NavbarTwo from "./Components/UI/NavbarTwo";
import Detail from "./Pages/Car/Detail";
import ContactUs from "./Pages/User/ContactUs";
import StripeContainer from "./Pages/Auth/StripeContainer";
import ContinuePayment from "./Pages/Car/ContinuePayment";
import StripeContainer2 from "./Pages/Auth/StripeContainer2";
import Gallery from "./Pages/Gallary/Gallery";
import Gallery2 from "./Pages/Gallary/EV9/Gallery";
import Modelos from "./Pages/Modelos/Modelos";
import Modelos2 from "./Pages/Modelos/EV9/Modelos";
import CardDetail from "./Components/CarDetail/CardDetail";
import RegisterNo from "./Pages/Auth/RegisterNo";
// import Threesixty from "./Pages/360view/Threesixty";
import ColorSection from "./Pages/ColorSection/ColorSection";
import RegisterDealer from "./Pages/Auth/RegisterDealer";
import DealersParticipantes from "./Components/CarDetail/DealersParticipantes";
import Features from "./Components/Features/Features";
import { Provider } from "react-redux";
import { Store } from "./Redux/MainStore";
import Contrato from "./Pages/Contrato/Contrato";
import ProfileIndex from "./Pages/Profile";
import ProfileDetailYes from "./Components/ProfileTabs/ProfileDetailYes";
import OrderHistory from "./Components/ProfileTabs/OrderHistory";
import ChangePassword2 from "./Components/ProfileTabs/ChangePassword";
import AddNewCard from "./Pages/Contrato/AddNewCard";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SecondConfirmOrder from "./Pages/Car/SecondConfirmOrder";
import Login2 from "./Pages/Auth/Login2";
import ReactGA from "react-ga";
import { Toaster } from "react-hot-toast";
import CanceledOrdersPage from "./Components/ProfileTabs/CanceledOrders";
import Popup from "./Components/UI/Popup";
import { Helmet } from "react-helmet";
import {
  Dollor4000Modal,
  GraciasModal,
  SelectCar,
} from "./Components/UI/Modal";
import ApiHook from "./Components/Hook/ApiHook";
import { setFullData, setOfferStatus } from "./Redux/CarSlice";
import { GlobalContext, GlobalContextProvider } from "./Context/GlobalContext";
import Separate from "./Pages/NewScreen/Separate";
import Chatbot from "./Pages/NewScreen/Chatbot";
import NewEV9model from "./Pages/NewScreen/NewEV9model";
import Dropdownmodel from "./Components/Modals/Dropdownmodel/Dropdownmodel";
import GalleryModal from "./Components/Modals/ForGallery/GalleryModal";
import CarDetailWrapper from "./Components/EV9Detail/CarDetailWrapper";
import EV9Specification from "./Components/EV9Detail/EV9Specification";
import Specificationthree from "./Components/CarDetail/Specificationthree";
import Specification from "./Components/CarDetail/Specification";
import Specificationtwo from "./Components/CarDetail/Specificationtwo";

function App() {
  ReactGA.initialize("G-41RWFRNJ1C");
  const StripePublicKey = process.env.REACT_APP_STRIP_KEY;
  const stripePromise = loadStripe(StripePublicKey);

  const [showPopup, setShowPopup] = useState(false);
  // const [Bono, setbono] = useState(false);
  const { data } = ApiHook("/get-offer-status");
  const navigate = useNavigate();
  const offerdata = JSON.parse(localStorage.getItem("offerdata"));
  useEffect(() => {
    const hasVisited = sessionStorage.getItem("hasVisited");
    if (!hasVisited) {
      setShowPopup(true);
      sessionStorage.setItem("hasVisited", true);
    }
    if (offerdata) {
      Store.dispatch(setFullData(offerdata));
    }
  }, []);

  useEffect(() => {
    if (data) {
      Store.dispatch(setOfferStatus(data.data.OfferSet));
    }
  }, [data]);

  return (
    <GlobalContextProvider>
      <Provider store={Store}>
        <Helmet>
          <title>Kia EV6 | Nuevo Modelo Eléctrico | Reserva en línea</title>
          <meta
            name="description"
            content="Descubre el nuevo Kia EV6, el nuevo modelo totalmente eléctrico con un rendimiento impresionante y tecnología innovadora. Reserva en línea tu modelo EV6, explora todas sus características y experimenta la próxima generación de movilidad sostenible."
          />
        </Helmet>
        <ScrollToTop />
        <Toaster position="top-center" reverseOrder={true} />
        <Routes>
          <Route path="/">
            <Route path="/*" element={<Error />} />
            <Route path="/" element={<Navigate replace to={"/Inicio"} />} />
            <Route path="/Inicio" element={<LandingLayout />} />
            {/* <Route path="/360" element={<Threesixty />} /> */}
            <Route path="/Colors" element={<ColorSection />} />
            <Route path="/Soporte" element={<ContactUs />} />
            <Route path="/Galeria" element={<Gallery />} />
            <Route path="/Galeria/EV9" element={<Gallery2 />} />
            <Route path="/Features" element={<Features />} />
            <Route
              path="/DealersParticipantes"
              element={<DealersParticipantes />}
            />

            <Route path="/Modelos" element={<Modelos />} />
            <Route path="/Modelos/EV9" element={<Modelos2 />} />

            <Route path="/Model" element={<CardDetail />} />
            <Route path="/Model/EV9" element={<CarDetailWrapper />} />
            <Route path="/Separate" element={<Separate />} />

            {/* Car */}
            <Route path="/Cart" element={<Cart />} />
            <Route path="/Order-Detail" element={<Detail />} />
            {/* <Route path="/Order" element={<Order />} /> */}
            <Route path="/ContinuePayment" element={<ContinuePayment />} />
            {/* <Route path="/Confirm-Order" element={<ConfirmOrder />} /> */}
            <Route path="/Confirm-Order" element={<SecondConfirmOrder />} />
            <Route path="/Contrato" element={<Contrato />} />

            {/* Auth Routes */}
            <Route path="/Iniciar-Sesion" element={<Login />} />
            <Route path="/Iniciar-Sesion-2" element={<Login2 />} />
            <Route path="/Cambiar-Contrasena" element={<ChangePassword />} />
            <Route path="/Intro" element={<Intro />} />
            <Route path="/Olvidaste-contrasena" element={<ForgotPassword />} />
            <Route path="/RegistrateAddress" element={<RegisterAddress />} />
            <Route path="/Registrate" element={<RegisterYes />} />
            <Route path="/RegistrateDealer" element={<RegisterDealer />} />
            <Route path="/Titular" element={<RegisterNo />} />
            <Route path="/RegistratePayment" element={<StripeContainer />} />
            <Route path="Perfil" element={<StripeContainer2 />} />
            <Route
              path="EspecificacionesEV6-EX-RWD"
              element={
                <Specification onClick={() => navigate("/Perfil/History")} />
              }
            />
            <Route
              path="EspecificacionesGT-LINE-RWD"
              element={
                <Specificationtwo onClick={() => navigate("/Perfil/History")} />
              }
            />
            <Route
              path="EspecificacionesEV6-GT-LINE-(E-AWD)"
              element={
                <Specificationthree
                  onClick={() => navigate("/Perfil/History")}
                />
              }
            />
            <Route
              path="EspecificacionesEV9"
              element={
                <EV9Specification onClick={() => navigate("/Perfil/History")} />
              }
            />

            {/* Policy Routes */}
            <Route path="/Politica" element={<NavbarTwo />}>
              <Route
                path="/Politica"
                element={<Navigate replace to={"/Politica/Privacidad"} />}
              />
              <Route path="/Politica/Privacidad" element={<Privacy />} />
              <Route
                path="/Politica/Compra-y-Devolucion"
                element={<PurchaseReturn />}
              />
              <Route
                path="/Politica/Terminos-y-Condiciones"
                element={<TermsConditions />}
              />
            </Route>

            {/* Faqs */}
            <Route path="/preguntas" element={<FAQ />} />
            <Route path="/preguntasFaqs" element={<FAQTwo />} />

            {/* Profile */}
            <Route path="/Perfil" element={<ProfileIndex />}>
              <Route
                path="/Perfil"
                element={<Navigate replace to={"/Perfil/MyProfile"} />}
              />
              <Route path="/Perfil/MyProfile" element={<ProfileDetailYes />} />
              <Route path="/Perfil/MyCard" element={<StripeContainer2 />} />
              <Route path="/Perfil/History" element={<OrderHistory />} />
              <Route
                path="/Perfil/CanceledOrders"
                element={<CanceledOrdersPage />}
              />
              <Route
                path="/Perfil/ChangePassword"
                element={<ChangePassword2 />}
              />
            </Route>
            <Route
              path="/AddnewCard"
              element={
                <>
                  <Elements stripe={stripePromise}>
                    <AddNewCard />
                  </Elements>
                </>
              }
            />
          </Route>
        </Routes>
        <Popup setShowPopup={setShowPopup} />
        {/* {Bono && (
          <Modal show={Bono} setbono={setbono} onHide={() => setbono(false)} />
        )} */}
        <Wraper />
        <Dollor4000Modal />
        <WraperSecond />
        <Chatbotwrapper />
        <Newev9modelwraper />
        <Newev9dropdown />
        <NewGallerydropdown />
      </Provider>
    </GlobalContextProvider>
  );
}

export default App;

const Wraper = () => {
  const { Separatemodal } = useContext(GlobalContext);
  return <div>{Separatemodal && <Separate />}</div>;
};

const WraperSecond = () => {
  const { Gracias } = useContext(GlobalContext);

  return <div>{Gracias.modal && <GraciasModal />}</div>;
};

const Chatbotwrapper = () => {
  const { chatbotshow } = useContext(GlobalContext);
  return <div>{chatbotshow && <Chatbot />}</div>;
};

const Newev9modelwraper = () => {
  const { Newev9 } = useContext(GlobalContext);
  return <div>{Newev9 && <NewEV9model />}</div>;
};

const Newev9dropdown = () => {
  const { modelsdropdown } = useContext(GlobalContext);
  return <div>{modelsdropdown && <Dropdownmodel />}</div>;
};

const NewGallerydropdown = () => {
  const { galleryModal } = useContext(GlobalContext);
  return <div>{galleryModal && <GalleryModal />}</div>;
};
