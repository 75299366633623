import React, { useState, useEffect } from "react";
import Footer from "../../Components/UI/Footer";
import "../../Css/ContactUs.css";
import Button from "../../Components/UI/Button";
import { useNavigate } from "react-router-dom";
import { Form, Formik, Field } from "formik";
import { ContactUsSchema } from "../../Schemas";
import axios from "../../API";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import NavbarThird from "../../Components/UI/NavbarThird";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  const Navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);

  // useEffect(() => {
  //   if (!localStorage.getItem("user-info")) {
  //     Navigate("/");
  //   }
  // }, []);

  const UserData = JSON.parse(localStorage.getItem("user-info"));

  const [IsError, setIsError] = useState("");
  const [Loading, setLoading] = useState(false);

  const initialValues = {
    FullName: "",
    Email: "",
    Phone: "",
    Subject: "",
    Comment: "",
  };

  const handleSubmit = async (values, { resetForm }) => {
    // console.log(values);
    resetForm();
    setLoading(true);

    try {
      const response = await axios.post(
        "/send-contactus",
        {
          Name: values.FullName,
          Email: values.Email,
          PhoneNumber: values.Phone,
          Subject: values.Subject,
          Message: values.Comment,
        }
        // {
        //   headers: {
        //     Authorization: `Bearer ${UserData.Token}`,
        //   },
        // }
      );

      // console.log("🚀 response.......", response)
      setIsError(response.data.message);

      if (response) {
        setLoading(false);
        // Navigate("/");
      }
    } catch (error) {
      // console.log("🚀 error", error)
      if (error) {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Kia EV6 | Contacto | Reserva en línea</title>
        <link rel="canonical" href="https://kiaevpr.com/#/Soporte" />
        <meta
          name="description"
          content="¿Necesitas orientación sobre el Kia EV6? Contáctanos para ayudarte con la reserva en línea de este nuevo modelo totalmente eléctrico."
        />
      </Helmet>
      <NavbarThird />
      <div className="top-top"></div>
      <section className="ContactUs-Main-sec">
        <div className="container white d-flex flex-column align-items-center justify-content-center height-div">
          <div className="w-100 d-flex justify-content-center pb-4">
            <span className="Heading-contactUs ">Contáctanos</span>
          </div>
          <div className="pb-3">
            <span className="form-Success-head">{IsError}</span>
          </div>
          <div className="w-100">
            <Formik
              initialValues={initialValues}
              validationSchema={ContactUsSchema}
              onSubmit={handleSubmit}
            >
              {(props) => (
                <Form
                  onSubmit={props.handleSubmit}
                  className="d-flex flex-column gap-4"
                >
                  <div className="d-flex flex-column w-100">
                    <div className="input--">
                      <span className="black">Nombre completo</span>
                      <Field
                        type="name"
                        name="FullName"
                        placeholder="Escribe tu nombre"
                        className="input-field"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.FullName}
                      />
                      <div className="p-1"></div>
                    </div>
                    {props.errors.FullName && props.touched.FullName ? (
                      <p className="form-error"> {props.errors.FullName} </p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="d-flex flex-column">
                    <div className="input--">
                      <span className="black">Correo electrónico</span>
                      <Field
                        type="email"
                        name="Email"
                        placeholder={"Escribe tu correo electrónico"}
                        className="input-field"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.Email}
                      />
                      <div className="p-1"></div>
                    </div>
                    {props.errors.Email && props.touched.Email ? (
                      <p className="form-error"> {props.errors.Email} </p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="d-flex flex-column">
                    <div className="input--">
                      <span className="black">Teléfono</span>
                      <Field
                        type="number"
                        name="Phone"
                        placeholder={"Escribe tu teléfono"}
                        className="input-field"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.Phone}
                      />
                      <div className="p-1"></div>
                    </div>
                    {props.errors.Phone && props.touched.Phone ? (
                      <p className="form-error"> {props.errors.Phone} </p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="d-flex flex-column">
                    <div className="input--">
                      <span className="black">Asunto</span>
                      <Field
                        type="text"
                        name="Subject"
                        placeholder={"Escribe tu asunto "}
                        className="input-field"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.Subject}
                      />
                      <div className="p-1"></div>
                    </div>
                    {props.errors.Subject && props.touched.Subject ? (
                      <p className="form-error"> {props.errors.Subject} </p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="d-flex flex-column">
                    <div className="input--textarea">
                      <span className="black">Comentarios</span>
                      <Field
                        as="textarea"
                        type="text"
                        name="Comment"
                        placeholder={"Déjanos tus comentarios"}
                        className="input-field"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.Comment}
                      />
                      <div className="p-1"></div>
                    </div>
                    {props.errors.Comment && props.touched.Comment ? (
                      <p className="form-error"> {props.errors.Comment} </p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="w-100 ">
                    <span>
                      {" "}
                      {
                        'Al hacer clic en "Enviar", acepto que se pongan en contacto conmigo en el número o correo electrónico proporcionado para ofrecerme más información sobre los productos. '
                      }{" "}
                    </span>
                  </div>

                  <div className="d-flex justify-content-center pt-3 pb-3">
                    <Button variant={"red"} type="submit">
                      {"Enviar"}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        {!Loading && <div></div>}
        {Loading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
              // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
      </section>
      <Footer />
    </>
  );
};

export default ContactUs;
