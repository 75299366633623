import React, { Suspense, lazy, useEffect, useState } from "react";
import "../../Css/CarDetail/CarDetail.css";
import Navbar from "../../Components/UI/NavbarTwo";
// import Modelone from "./Modelone";
import Footer from "../UI/Footer";
import Modelfour from "./Modelfour";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { CircularProgress } from "@mui/material";
const Modeltwo = lazy(() => import("./Modeltwo"));
const Modelthree = lazy(() => import("./Modelthree"));
// import Modeltwo from "./Modeltwo";
// import Modelthree from "./Modelthree";

const CardDetail = () => {
  const data = localStorage.getItem("cardindex");
  const [steps, setsteps] = useState(data);

  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);

  return (
    <>
      <Navbar />
      <div className="cardetailpage">
        <div className="modelselectdiv">
          <span
            className={steps == 0 ? "spanactive" : ""}
            onClick={() => setsteps(0)}
          >
            {/* CVE2-23 */}
            EV6 EX RWD
          </span>
          <span
            className={steps == 1 ? "spanactive" : ""}
            onClick={() => setsteps(1)}
          >
            {/* CVGL1-23 */}
            EV6 GT-LINE RWD
          </span>
          <span
            className={steps == 2 ? "spanactive" : ""}
            onClick={() => setsteps(2)}
          >
            {/* CV4GL1-23  */}
            EV6 GT-LINE (E-AWD)
          </span>
        </div>
        <div className="allmo">
          {/* {steps == 0 ? <Modelone /> : null} */}
          {steps == 0 ? (
            <Suspense
              fallback={
                <div className="fallbackloading">
                  <CircularProgress />
                </div>
              }
            >
              <Modeltwo />
            </Suspense>
          ) : null}
          {steps == 1 ? (
            <Suspense
              fallback={
                <div className="fallbackloading">
                  <CircularProgress />
                </div>
              }
            >
              <Modelthree />
            </Suspense>
          ) : null}
          {steps == 2 ? (
            <Suspense
              fallback={
                <div className="fallbackloading">
                  <CircularProgress />
                </div>
              }
            >
              <Modelfour />
            </Suspense>
          ) : null}
          {/* {!steps == 0 ? <Modelone/>: null} */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CardDetail;
